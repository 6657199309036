<template>
  <div class="content p-0">
    <card v-if="customerBookings" class="minHeight">
      <div class="col">
        <div>
          <base-button
              type="cancel"
              class="my-3"
              @click="goBack()"
          >{{$t("goBack") }}</base-button>
        </div>
      </div>
      <hr />
      <!-- Next bookings -->
      <h2 v-if="nextBookings().length > 0" class="ml-2 mt-4 ml-md-5">
        {{ $t("bookings.next") }}:
      </h2>
      <div
          v-for="booking in nextBookings()"
          :key="booking.id"
          class="row border-bottom py-3 mx-1 mx-md-5 mb-1 mb-md-3"
          :class="{ 'customer-appointment-btn': isCustomer}"
          >
          <div @click="openBooking(booking)" class="d-flex flex-wrap w-100 ">
            <section class="order-0 order-sm-0 d-flex flex-wrap d-sm-block align-items-center col-12 col-sm-2 text-center containerDate">
            <p class="order-1 order-sm-0">
              {{
                $t(`bookings.months.${new Date(booking.datetime).getMonth() + 1}`)
              }}
            </p>
            <h3 class="mb-1 pr-2 px-md-3 px-sm-0 order-0 order-sm-1">{{ new Date(booking.datetime).getDate() }}</h3>
            <p class="order-2 pl-2">
              {{
                !(new Date(booking.datetime).getHours() >= 10)
                    ? "0" + new Date(booking.datetime).getHours()
                    : new Date(booking.datetime).getHours()
              }}:{{
                !(new Date(booking.datetime).getMinutes() >= 10)
                    ? "0" + new Date(booking.datetime).getMinutes()
                    : new Date(booking.datetime).getMinutes()
              }}h.
            </p>
            <div class="d-sm-none order-3 pill">
              <tabs-pills
                  :type="booking.color"
                  rowClass="pillContainer"
                  tabNavWrapperClasses="mb-2"
                  tabContentClasses="d-none"
                  tabListClasses="py-0 cursor-default col-12"
              >
                <tab-pane
                    :label="$t(`bookings.status.${booking.status}`)"
                ></tab-pane>
              </tabs-pills>
          </div>
        </section>
        <section class="order-2 order-sm-1 separator my-2 my-md-3" />
        <section class="order-1 order-sm-2 col-8 col-sm-5 align-self-center ml-md-4">
          <tabs-pills
              :type="booking.color"
              rowClass="pillContainer"
              tabNavWrapperClasses="mb-2"
              tabContentClasses="d-none"
              tabListClasses="d-none d-sm-block py-0 px-5 cursor-default col-12 order-2"
          >
            <tab-pane
                :label="$t(`bookings.status.${booking.status}`)"
            ></tab-pane>
          </tabs-pills>
          <p class="order-0">{{ booking.service.name }}</p>
          <p v-if="isCustomer">{{ booking.store.name }}</p>
          <p class="font-size text-muted order-1">
            {{ booking.service.time }}min <strong class="px-1">·</strong>
            {{ booking.employee.name }}
          </p>
        </section>
        <section class="order-3 order-sm-3 col-3 col-sm-2 d-flex align-items-center p-0 text-center m-auto containerPrice">
          <h3 class="col-12 font-weight-bold p-0 m-0 m-md-3">{{booking.service.price}} €</h3>
        </section>
        </div>
        <section v-if="isCustomer && booking.status !== 'canceled'" class="order-4 col-12 d-md-flex justify-content-md-around my-auto py-2 px-md-3">
          <base-button
              class="add-booking-btn col-12 col-md-4 col-lg-3 col-xl-2 px-2 px-sm-1 px-lg-3 mr-auto"
              simple
              @click="addBookingToGoogleCalendar(booking)"
            >
              <span class="p-0 m-0">{{ $t("addToCalendar") }}</span>
            </base-button>


            <base-button
              class="share-booking-btn col-12 col-md-4 col-lg-3 col-xl-2 px-2 px-sm-1 px-lg-3 ml-auto"
              simple
              @click="shareBooking(booking)"
            >
              <span class="p-0 m-0">{{ $t("shareBooking") }}</span>
            </base-button>
        </section>
      </div>
      <!-- Past bookings -->
      <h2 v-if="pastBookings().length > 0" class="ml-2 mt-4 ml-md-5">
        {{ $t("bookings.past") }}:
      </h2>
      <div
          v-for="booking in pastBookings()"
          :key="booking.id"
          class="row border-bottom py-3 mx-1 mx-md-5 mb-1 mb-md-3"
      >
        <section class="order-0 order-sm-0 d-flex flex-wrap d-sm-block align-items-center col-12 col-sm-2 text-center containerDate">
          <p class="order-1 order-sm-0">
            {{
              $t(`bookings.months.${new Date(booking.datetime).getMonth() + 1}`)
            }}
          </p>
          <h3 class="mb-1 pr-2 px-md-3 px-sm-0 order-0 order-sm-1">{{ new Date(booking.datetime).getDate() }}</h3>
          <p class="order-2 pl-2">
            {{
              !(new Date(booking.datetime).getHours() >= 10)
                  ? "0" + new Date(booking.datetime).getHours()
                  : new Date(booking.datetime).getHours()
            }}:{{
              !(new Date(booking.datetime).getMinutes() >= 10)
                  ? "0" + new Date(booking.datetime).getMinutes()
                  : new Date(booking.datetime).getMinutes()
            }}
          </p>
          <div class="d-sm-none order-3 pill">
            <tabs-pills
                :type="booking.color"
                rowClass="pillContainer"
                tabNavWrapperClasses="mb-2"
                tabContentClasses="d-none"
                tabListClasses="py-0 cursor-default col-12"
            >
              <tab-pane
                  :label="$t(`bookings.status.${booking.status}`)"
              ></tab-pane>
            </tabs-pills>
          </div>
        </section>
        <section class="order-2 order-sm-1 separator my-2 my-md-3" />
        <section class="order-1 order-sm-2 col-8 col-sm-5 align-self-center ml-md-4">
          <tabs-pills
              :type="booking.color"
              rowClass="pillContainer"
              tabNavWrapperClasses="mb-2"
              tabContentClasses="d-none"
              tabListClasses="d-none d-sm-block py-0 px-5 cursor-default col-12 order-2"
          >
            <tab-pane
                :label="$t(`bookings.status.${booking.status}`)"
            ></tab-pane>
          </tabs-pills>
          <p class="order-0">{{ booking.service.name }}</p>
          <p v-if="isCustomer">{{ booking.store.name }}</p>
          <p class="font-size text-muted order-1">
            {{ booking.service.time }}m · {{ booking.employee.name }}
          </p>
        </section>
        <section class="order-3 order-sm-3 col-3 col-sm-2 d-flex align-items-center p-0 text-center m-auto containerPrice">
          <h3 class="col-12 font-weight-bold p-0 m-0 m-md-3">{{ booking.service.price }}€</h3>
        </section>
        <section v-if="isCustomer" class="order-4 col-12 d-md-flex justify-content-md-around my-auto py-2 px-md-3">
          <base-button
              simple
              class="col-12 col-md-4 col-lg-3 col-xl-2 rebookBtn px-2 px-sm-1 px-lg-3 mr-auto"
              @click="repeatAppointment(booking)"
          >
            <span>{{ $t('rebook') }}</span>
          </base-button>
          <template v-if="booking.status !== 'canceled' && booking.status !== 'rejected' && booking.status !== 'absence'">
            <base-button
                v-if="booking.review === null"
                simple
                class="col-12 col-md-4 col-lg-3 col-xl-2 rateNowBtn px-2 px-sm-1 px-lg-3 ml-auto"
                @click="showRateModal(booking)"
            >
              <span>{{ $t('rateNow') }}</span>
            </base-button>
            <div v-else class="d-flex justify-content-center my-2">
              <div class="col-6 d-flex justify-content-center">
                <div v-for="n in 5" :key="n">
                  <i  class="lni star mr-2"
                      :class="{'lni-star-filled': n <= booking.review.general, 'lni-star': n > booking.review.general, }"
                  ></i>
                </div>
              </div>
            </div>
          </template>
        </section>
      </div>
    </card>
    <modal :show="isModalShow" :showClose="false">
      <template v-if="ratedBooking">
        <div v-for="rate in Object.keys(ratings)" :key="rate" class="d-block d-md-flex  align-items-center px-2 mb-3">
          <p class="col-6 titleRatings">{{ $t(`ratings.${rate}`) }} : </p>
          <div class="col d-flex justify-content-start align-items-center pl-md-4">
            <div v-for="n in 5" :key="n">
              <i  class="lni starRating pointer starHover mr-3"
                  :class="{'lni-star-filled': n <= ratings[rate], 'lni-star': n > ratings[rate] }"
                  @click="ratings[rate] = n"></i>
            </div>
          </div>
        </div>
      </template>
      <template slot="footer">
        <base-button type="danger" @click="clearModal">
          {{ $t("cancel") }}
        </base-button>
        <base-button type="primary" @click="setRate(ratedBooking.id)" :disabled="isBtnRateDisabled || isDisableRatingBtn">
          {{ $t("rate") }}
        </base-button>
      </template>
    </modal>
  </div>
</template>

<script>
import { DateTime, Settings } from "luxon";
import { Card, TabsPills, TabPane, BaseButton, Modal } from '@/components/index';
import { mapState } from 'vuex';

export default {
  name: "CustomerAppointments",
  props: ["customerBookings"],
  data () {
    return {
      routeReview: '/reviews',
      isModalShow: false,
      ratedBooking: null,
      isBtnRateDisabled: false,
      ratings: {
        store: 0,
        employee: 0,
        general: 0
      },
    }
  },
  components: {
    BaseButton,
    Card,
    TabsPills,
    TabPane,
    Modal
  },
  computed: {
    isDisableRatingBtn() {
      const values = Object.values(this.ratings);
      return !values.every( value => value > 0 );
    },
    ...mapState(["isCustomer"]),
  },
  methods: {
    pastBookings() {
      let bookings = [];
      this.customerBookings?.forEach((booking) => {
        booking.isRating = booking.isRating? booking.isRating : false;
        if (typeof booking.datetime === 'number' && !isNaN(booking.datetime)) {
          booking.datetime =   DateTime.fromMillis( booking.datetime ).toMillis() ;
        }else{
          booking.datetime =   DateTime.fromFormat( booking.datetime,"yyyy-LL-dd HH:mm").toMillis()
        }
        if (booking.status === "new") booking.color = "info";
        if (booking.status === "pending") booking.color = "warning";
        if (booking.status === "accepted") booking.color = "booking_completed";
        if (booking.status === "completed") booking.color = "booking_success";
        if (booking.status === "canceled") booking.color = "danger";
        if (booking.status === "rejected") booking.color = "rejected";
        if (booking.status === "absence") booking.color = "absence";
        if (booking.status === "employee_absence") booking.color = "employee_absence";
        if (booking.status === "confirmed") booking.color = "booking_success";
        if (new Date(booking.datetime) < new Date()) {
          bookings.push(booking);
        }
      });
      bookings.sort((a, b) =>
          b.datetime > a.datetime ? 1 : a.datetime > b.datetime ? -1 : 0
      );
      return bookings;
    },
    nextBookings() {
      let bookings = [];
      this.customerBookings?.forEach((booking) => {        
        if (typeof booking.datetime === 'number' && !isNaN(booking.datetime)) {
          booking.datetime =   DateTime.fromMillis( booking.datetime ).toMillis() ;
        }else{
          booking.datetime =   DateTime.fromFormat( booking.datetime,"yyyy-LL-dd HH:mm").toMillis()
        }        
        if (booking.status === "new") booking.color = "info";
        if (booking.status === "pending") booking.color = "warning";
        if (booking.status === "accepted") booking.color = "booking_success";
        if (booking.status === "completed") booking.color = "booking_completed";
        if (booking.status === "canceled") booking.color = "danger";
        if (booking.status === "rejected") booking.color = "rejected";
        if (booking.status === "absence") booking.color = "absence";
        if (booking.status === "employee_absence") booking.color = "employee_absence";
        if (booking.status === "confirmed") booking.color = "booking_success";
        if (new Date(booking.datetime) > new Date()) {
          bookings.push(booking);
        }
      });
      bookings.sort((a, b) =>
          b.datetime < a.datetime ? 1 : a.datetime < b.datetime ? -1 : 0
      );
      return bookings;
    },
    openBooking(booking){
      if(!this.isCustomer) return
      this.$emit("openBooking", booking);
    },
    goBack(){
      let route = this.isCustomer ? 'storeList' : 'customerList'
      this.$router.push({ name: route })
    },
    repeatAppointment( booking ) {
      const { employee, service, store } = booking;

      if( employee.uuid && service.slug && store.slug ) {
        this.$store.dispatch('setEmployeeToRebook', employee.uuid );
        this.$router.push(`/${store.slug}/${service.slug}/reservar`);
      }
    },
    showRateModal(booking) {
      this.ratedBooking = booking;
      this.isModalShow = true;
    },
    async setRate(bookingId) {
      this.isBtnRateDisabled = true;
      const data = {
        booking_id: bookingId.toString(),
        general: this.ratings.general,
        store: this.ratings.store,
        employee: this.ratings.employee
      }

      let response = await this.axios.post(this.routeReview, data);
      if (response && response.data.status === "success") {
        this.clearModal();

        this.$emit('reloadBookings')
        this.isBtnRateDisabled = false;
      }
    },
    clearModal() {
      this.isModalShow = false;
      this.ratedBooking = null;
      this.ratings = {
        store: 0,
        employee: 0,
        general: 0
      }
    },
    addBookingToGoogleCalendar(booking) {
      const regex = /[-.:]/gm;
      const { service, store, datetime, employee } = booking;

      let bookingData = {
        title: `${service.name} ${this.$t('at')} ${store.name}`, 
        start: DateTime.fromMillis(datetime).toISO().replace(regex, ''),
        end: DateTime.fromMillis(datetime).plus({ minutes: service.time }).toISO().replace(regex, ''),
        location: store.address,
        location: store.address,
        description: `${service.name} ${this.$t('at')} ${store.name}.%0A${this.$t('servedBy')}: ${employee.name}.%0A${this.$t('price')}: ${service.price} .%0A${this.$t('forMoreDetails')}:%0A${window.location.origin}/myAppointments`,
      };

      const URL = `https://www.google.com/calendar/render?action=TEMPLATE&text=${bookingData.title}&details=${bookingData.description}&location=${bookingData.location}&dates=${bookingData.start}%2F${bookingData.end}`;
      window.open(URL, '_blank');
    },
    shareBooking(booking) {
      const text = `${this.$t('shareBookingText.bookingOn')} ${DateTime.fromMillis(booking.datetime).toFormat("dd/LL/yyyy HH:mm")} ${this.$t('shareBookingText.for')} ${booking.service.name} ${this.$t('shareBookingText.on')} ${booking.store.name} (${booking.store.address})`;

      if(navigator.share) {
        navigator.share({
          title: this.$t('shareBooking'),
          text: `${this.$t('shareBookingText.bookingOn')} ${DateTime.fromMillis(booking.datetime).toFormat("dd/LL/yyyy HH:mm")} ${this.$t('shareBookingText.for')} ${booking.service.name} ${this.$t('shareBookingText.at')} ${booking.store.name} (${booking.store.address})`,
          url: process.env.VUE_APP_URL
        }).then( () => {
          this.$toast.success(this.$t("notifications.success.thanksForSharing"));
        }).catch( console.error )
      } else if (navigator?.clipboard) {
        navigator.clipboard.writeText( text ).then( () => {
          this.$toast.success(this.$t("bookingCopiedToClipboard"));
        })
      } else {
        this.$toast.error(this.$t("notifications.error.bySharingTryManually"));
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/white-dashboard.scss';

.minHeight {
  min-height: 90vh !important;
}
.separator {
  border-right: 1px solid #e9ecef;
}
.font-size {
  font-size: 12px;
}
.containerDate {
  height: auto;
}
.containerDate > h3 {
  min-width: 35px;
}
.customer-appointment-btn{
  cursor: pointer;
  &:hover{
    background-color: #F5F6FA;
  }
}
.containerPrice > h3 {
  min-width: 75px;
}
.rateNowBtn:hover {
  border-color: $secondary!important;
  color: $secondary!important;
}
.star {
  font-size: 1rem;
}
.starRating {
  font-size: 1.5rem;
}
.starHover:hover {
  color: $primary;
}
.pointer {
  cursor: pointer;
}
.titleRatings {
  font-size: 1rem;
}
.lni-star-filled {
  color: $tertiary;
}
.rebookBtn {
  color: $secondary!important;
  border-color: $secondary!important;
}

.share-booking-btn ,.add-booking-btn {
  border-color: $secondary!important;
  color: $secondary!important;
  z-index: 9;
}

@media (min-width: 330px) {
  .pill {
    margin-right: auto;
    width: 100%;
  }
}
@media (min-width: 415px) {
  .pill {
    margin-right: 0;
    margin-left: 5px;
  }
}
</style>